import {createAction, props} from "@ngrx/store";
import {IOpportunity} from "../../../shared/model/opportunity.model";
import {IServiceRegistration} from "../../../shared/model/service-registration.model";
import {ISupervisor} from "../../../shared/model/supervisor.model";

export const setCurrentSupervisorAction = createAction(
  '[Opportunity Config] Set Current Supervisor',
  props<{supervisor: ISupervisor}>()
);

export const setIsLeadSupervisorAction = createAction(
  '[Opportunity Config] Set IsLead Supervisor',
  props<{isLead: boolean}>()
);

export const addVolunteerRecordAction = createAction(
  '[Opportunity Config] Add Volunteer Record',
  props<{record: IServiceRegistration}>()
);

export const updateVolunteerRecordAction = createAction(
  '[Opportunity Config] Update Volunteer Record',
  props<{record: IServiceRegistration}>()
);

export const removeVolunteerRecordAction = createAction(
  '[Opportunity Config] Remove Volunteer Record',
  props<{record: IServiceRegistration}>()
);

export const addSupervisorRecordAction = createAction(
  '[Opportunity Config] Add Supervisor Record',
  props<{record: ISupervisor}>()
);

export const removeSupervisorRecordAction = createAction(
  '[Opportunity Config] Remove Supervisor Record',
  props<{record: ISupervisor}>()
);

export const addSupervisorEvalPendingRecordAction = createAction(
  '[Opportunity Config] Add Supervisor Eval Pending Record',
  props<{record: IServiceRegistration}>()
);

export const removeSupervisorEvalPendingRecordAction = createAction(
  '[Opportunity Config] Remove Supervisor Eval Pending Record',
  props<{record: IServiceRegistration}>()
);

export const addRegistrationRequestRecordAction = createAction(
  '[Opportunity Config] Add Registration Request Record',
  props<{record: IServiceRegistration}>()
);

export const updateRegistrationRequestRecordAction = createAction(
  '[Opportunity Config] Update Registration Request Record',
  props<{record: IServiceRegistration}>()
);

export const removeRegistrationRequestRecordAction = createAction(
  '[Opportunity Config] Remove Registration Request Record',
  props<{record: IServiceRegistration}>()
);

export const setOpportunityDataAction = createAction(
  '[Opportunity Config] Set Opportunity Data',
  props<{opportunity: IOpportunity}>()
);

export const clearOpportunityDataAction = createAction(
  '[Opportunity Config] Clear Opportunity Data'
);

